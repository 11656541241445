<template>
  <div class='flex flex-row-reverse justify-start'>
    <div class='p-8 rounded-md bg-gray-100 w-72'>
      <div class='mb-4'>
        <label class='block'>Title (필수항목)</label>
        <input type='text' class='form-input w-full' v-model='meetingNote.title'  placeholder='필수항목'>
      </div>
      <div class='mb-4'>
        <label class='block'>Meeting Type</label>
        <select v-model='meetingNote.meeting_type' class='form-select w-full'>
          <option v-for='type in types' :key='`meeting-type-${type}`'>
            {{type}}
          </option>
        </select>
      </div>
      <div class='mb-4'>
        <label class='block'>Meeting Date</label>
          <el-date-picker
          v-model='meetingNote.meeting_date'
          type='date'
          placeholder='Meeting On'
          class='w-full'/>
      </div>
      <div class='mb-4'>
        <label class='block'>내부 참석자</label>
        <custom-field-user :field='meetingNote.internal_attendee_field' :is-view-mode='false' @update-value='updateInternalAttendeeField'></custom-field-user>
        <label class='block mt-5'>외부 참석자 <button class='ml-2 mb-2 text-gray-600 text-xs' @click='clearContacts'>(clear)</button></label>
        <contact-search @update-selected-contact='updateSelectedContact'/>
        <ul>
          <li v-for='contact in meetingNote.contacts'
              v-bind:key='`contact-${contact.id}`' class='inline-block'>
            {{ contact.contact_name }}<x-circle-icon class='inline-block mb-5 p-1' @click='removeContact(contact.id)' />
          </li>
        </ul>
      </div>
      <div class='mb-4'>
        <label class='block mb-1'>{{investmentOrCase}} (필수항목)</label>
        <investment-auto-suggest :investment-id='selectedInvestmentId'
                                 @update-selected-investment='updateSelectedInvestment'/>
      </div>
      <div class='mb-4'>
        <label class='block'>Options</label>
        <label for='shareable'>
          <input type='checkbox' name='shareable' id='shareable' class='form-checkbox' v-model='meetingNote.shareable'>
          Shareable
        </label>
      </div>
      <div class='mt-12 border-t pt-12'>
        <button :disabled='deactivateSaveButton' class='border px-8 h-12 text-white rounded-md font-bold hover:shadow-md' :class='saveButtonCss' @click='submitMeetingNote'>{{buttonActionText}}</button>
        <button class='ml-4 px-8 h-12 border rounded-md border-transparent hover:border-gray-500' @click='cancelEditing'>취소</button>
      </div>
    </div>
    <div class='flex-grow mr-8' style='height: calc(100vh - 8rem)'>
      <vue-editor v-model='meetingNote.memo' class='bg-white' style='height: calc(100% - 3rem)' />
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import ContactSearch            from '@/components/contacts/ContactSearch.vue'
import InvestmentAutoSuggest    from '@/components/InvestmentAutoSuggest.vue'
import CustomFieldUser          from '@/components/custom_fields/CustomFieldUser.vue'
import cloneDeep                from 'lodash/cloneDeep'
import { VueEditor }            from 'vue2-editor'
import dayjs                    from 'dayjs'
import { XCircleIcon }          from '@vue-hero-icons/outline'

export default {
  name: 'MeetingNoteEdit',
  components: {
    ContactSearch,
    InvestmentAutoSuggest,
    VueEditor,
    XCircleIcon,
    CustomFieldUser,
  },
  data () {
    return {
      types: [
        '전화',
        '미팅',
        'IR',
        'Private',
        '기타',
      ],
      meetingNote: {
        id: 0,
        title: '',
        meeting_date: new Date(),
        investment_id: 0,
        memo: '',
        internal_attendee_field: {},
        contacts: [],
        shareable: false,
        meeting_type: '',
      },
      selectedInvestmentId: 0,
      internalAttendeeField: {},
    }
  },
  watch: {
    selectedInvestmentId: {
      handler: function (newVal) {
        if (newVal) {
          this.meetingNote.investment_id = newVal
        }
      },
      immediate: true
    },
    editingMeetingNote: {
      handler: function (newVal) {
        if (newVal) {
          this.meetingNote = cloneDeep(newVal)
          this.selectedInvestmentId = cloneDeep(newVal.investment_id)
        }
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    ...mapState('meetingNotes', [
      'editingMeetingNote',
    ]),
    ...mapState('customFields', [
      'customFieldTemplates',
    ]),
    isExistingMeetingNote () {
      return this.meetingNote.id > 0
    },
    buttonActionText () {
      return this.isExistingMeetingNote ? 'Save Changes' : 'Create Note'
    },
    investmentOrCase () {
      return 'investment'
    },
    deactivateSaveButton () {
      return (this.meetingNote.title === '' || !(this.selectedInvestmentId > 0))
    },
    saveButtonCss () {
      return this.deactivateSaveButton ? 'bg-gray-400' : 'bg-gray-900'
    }
  },
  methods: {
    ...mapActions('meetingNotes', [
      'updateMeetingNote',
      'createMeetingNote',
      'doneEditingMeetingNote',
    ]),
    ...mapActions('customFields', [
      'createOrUpdateCustomFieldValue',
    ]),
    submitMeetingNote () {
      this.meetingNote.meeting_date = dayjs(this.meetingNote.meeting_date).format('YYYY-MM-DD')
      this.internalAttendeeField.investment_id = 0
      this.internalAttendeeField.custom_field_template_id = this.customFieldTemplates.find(template => template.template_type === 'meeting_note' && template.field_type === 'user').id
      if (this.isExistingMeetingNote) {
        this.internalAttendeeField.meeting_note_id = this.meetingNote.id
        this.updateMeetingNote(this.meetingNote)
        this.createOrUpdateCustomFieldValue(this.internalAttendeeField)
      } else {
        this.createMeetingNote(this.meetingNote).then(resp => {
          this.internalAttendeeField.meeting_note_id = resp.id
          this.createOrUpdateCustomFieldValue(this.internalAttendeeField)
        })
      }
      this.doneEditingMeetingNote()
    },
    cancelEditing () {
      this.doneEditingMeetingNote()
    },
    updateSelectedContact (newContact) {
      if (!this.meetingNote.contacts.includes(newContact)) {
        this.meetingNote.contacts.push(newContact)
      }
    },
    updateSelectedInvestment (selectedInvestmentId) {
      this.selectedInvestmentId = selectedInvestmentId
    },
    removeContact (contactId) {
      const index = this.meetingNote.contacts.findIndex(contact => contact.id === contactId)
      if (index >= 0) {
        this.meetingNote.contacts.splice(index, 1)
      }
    },
    clearContacts () {
      this.meetingNote.contacts = []
    },
    updateInternalAttendeeField (field) {
      this.internalAttendeeField.value = field.value.toString()
    }
  },
}
</script>
